/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'zone.js';
import 'core-js/es7/array';
import 'core-js/es7/object';

import 'url-search-params-polyfill';
// import 'core-js/features/url';
// import 'core-js/features/url-search-params';